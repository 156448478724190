<template>
  <div class="portfolio container">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6">
          <h1 class="mb-2 text-title">Portfólio</h1>
          <h2 class="mb-5 text-caption">Vue.js + Node.js</h2>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-5 order-lg-1">
            <!-- <img src="@/assets/portfolio-dark.png" class="rounded mx-auto img-fluid" alt="Portfólio"> -->
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="@/assets/images/portfolio/portfolio-dark.png" class="d-block w-100" alt="Portfólio">
                </div>
                <div class="carousel-item">
                  <img src="@/assets/images/portfolio/portfolio-dark.png" class="d-block w-100" alt="Portfólio">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <p>
              Eu gosto de trabalhar com desenvolvimento de aplicações, adoro tirar ideias do papel e trazer para o mundo real. Bla bla bla bla bla bla bla.
              Eu gosto de trabalhar com desenvolvimento de aplicações, adoro tirar ideias do papel e trazer para o mundo real. Bla bla bla bla bla bla bla.
              Eu gosto de trabalhar com desenvolvimento de aplicações, adoro tirar ideias do papel e trazer para o mundo real. Bla bla bla bla bla bla bla.
            </p>
            <h2 class="mt-5 text-caption">{{ $t('seeMoreAt') }}:</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-auto skill-items">
            <i class="lab la-figma"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeBackground } from "@/assets/js/background.js";
export default {
  name: 'Portfolio',
  created() {
    changeBackground("portfolio");
  }
}
</script>